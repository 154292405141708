import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	root: {
		display: 'flex',
	},
	dateTimeWrapper: {
		width: '72px',
		height: '72px',
		border: '1px solid #C4C4C4',
		borderRadius: '16px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'end',
		position: 'relative',
		marginRight: '26px',
		'&::after': {
			content: '":"',
			position: 'absolute',
			right: '-20px',
			top: '-5px',
			fontSize: '50px',
			[theme.breakpoints.down('sm')]: {
				fontSize: '40px',
				right: '-15px',
			},
		},
		'&:last-of-type': {
			marginRight: '0px',
			'&::after': {
				content: '" "',
			},
		},
		[theme.breakpoints.down('md')]: {
			width: '65px',
			height: '65px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '55px',
			height: '55px',
			marginRight: '20px',
		},
	},
	dateTime: {
		...theme.typography.h4,
		[theme.breakpoints.down('md')]: {
			...theme.typography.h5,
		},
		[theme.breakpoints.down('sm')]: {
			...theme.typography.h6,
		},
	},
	dateTimeLabel: {
		...theme.typography.caption,
		color: '#6D6D6D',
		marginBottom: '7px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '10px',
		},
	},
}));

export default useStyles;
