import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { loaderStages } from '@/utils/constants/delayedActionPopup';
import { formTypes } from '@/utils/constants/formTypes';
import { textValues } from './constants';

// components
import DelayedActionPopup from '@/components/shared/DelayedActionPopup';
import Timer from '@/components/shared/timer';
import MainFormBoxWrap from './mainFormBoxWrap';
import PaymentFormBoxWrap from './paymentFormBoxWrap';

// material
import { Box } from '@mui/material';

// styles
import useStyles from './style';

export default function FormBoxWrap({ formType, timer, ...props }) {
	const { classes } = useStyles();
	const paymentState = useSelector((state) => state.common.paymentState);

	let FormWrapComponent;
	switch (formType) {
		case formTypes.paymentForm: {
			FormWrapComponent = PaymentFormBoxWrap;
			break;
		}

		default:
			FormWrapComponent = MainFormBoxWrap;
	}

	return (
		<Box>
			{timer && (
				<Box className={classes.timerWrap}>
					<Timer {...timer} />
				</Box>
			)}
			<FormWrapComponent {...props} />
			<DelayedActionPopup
				open={!!paymentState}
				state={paymentState}
				title={
					paymentState === loaderStages.verifying
						? textValues.verifyingTitle
						: paymentState === loaderStages.success
						? textValues.successTitle
						: ''
				}
				caption={get(props, 'paymentDetail.captionForVerifyModal')}
			/>
		</Box>
	);
}

FormBoxWrap.propTypes = {
	formType: PropTypes.string,
	timer: PropTypes.object,
};
