import PropTypes from 'prop-types';

// constants
import { loaderStages } from '@/utils/constants/delayedActionPopup';

// components
import PaymentVerify from '@/assets/icons/paymentVerify';
import Spinner from '@/components/widgets/spinner';

// material
import { Box, Dialog, Typography } from '@mui/material';

// style
import useStyles from './style';

export default function DelayedActionPopup({ open, state, title, caption }) {
	const { classes, cx } = useStyles();

	return (
		<Dialog
			fullWidth={true}
			maxWidth="sm"
			open={open}
			classes={{ paper: classes.dialogPaper }}
		>
			<Box
				className={cx(
					classes.infoWrap,
					state === loaderStages.inProgress ? classes.zeroOpacity : ''
				)}
			>
				<PaymentVerify />
				<Typography color="primary" variant="h6" className={classes.title}>
					{title || ''}
				</Typography>
				{caption && (
					<Typography
						variant="caption"
						component="p"
						fontWeight="fontWeightMedium"
					>
						{caption}
					</Typography>
				)}
			</Box>
			<Box
				className={cx(
					classes.spinnerWrap,
					state === loaderStages.inProgress ? '' : classes.zeroOpacity
				)}
			>
				<Spinner />
			</Box>
		</Dialog>
	);
}

DelayedActionPopup.propTypes = {
	open: PropTypes.bool,
	state: PropTypes.string,
	title: PropTypes.string.isRequired,
	caption: PropTypes.string,
};

DelayedActionPopup.defaultProps = {
	open: false,
};
