import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, props = {}) => ({
	timerWrap: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: theme.spacing(2),
	},
	formContainer: {
		textAlign: 'center',
		justifyContent: 'center',
		minHeight: props.height || '100%',
		maxHeight: props.height || '100%',
		width: '100%',
		boxShadow: props.noShadow ? 'none' : '0 3px 16px 0 rgba(0, 0, 0, 0.08)',
		[theme.breakpoints.down('sm')]: {
			boxShadow: props.noShadow ? 'none' : '0 3px 16px 0 rgba(0, 0, 0, 0.08)',
		},
	},
	modalFormPaper: {
		borderRadius: '15px',
		backgroundColor: theme.palette.common.white,
	},
	iconButtonWrap: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
}));

export default useStyles;
