// Material
// Utils
import { getFutureEventDate } from '@/utils/getFutureEventDate';
import { Box } from '@mui/material';
// Defaults
import PropTypes from 'prop-types';
import React from 'react';
// Styles
import useStyles from './style';

// Constants
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export default function Timer({
	dateTime,
	isRecurring,
	repeatEvery,
	count,
	dayList,
}) {
	const { classes } = useStyles();

	const [countDownDateTime] = React.useState(
		isRecurring
			? getFutureEventDate({ dateTime, repeatEvery, count, dayList })
			: Date.parse(dateTime)
	);
	const [days, setDays] = React.useState(0);
	const [hours, setHours] = React.useState(0);
	const [minutes, setMinutes] = React.useState(0);
	const [seconds, setSeconds] = React.useState(0);

	const formatDateTime = (value) => {
		return typeof new Number(value) === 'object' && `${value}`.length == 1
			? `0${value}`
			: `${value}`;
	};

	React.useEffect(() => {
		let id = setInterval(() => {
			let dateTimeNow = new Date().getTime();
			let difference = countDownDateTime - dateTimeNow;

			if (difference > 0) {
				const days = Math.floor(difference / DAY);
				difference -= days * DAY;
				const hours = Math.floor(difference / HOUR);
				difference -= hours * HOUR;
				const minutes = Math.floor(difference / MINUTE);
				difference -= minutes * MINUTE;
				const seconds = Math.floor(difference / SECOND);

				setDays(days);
				setHours(hours);
				setMinutes(minutes);
				setSeconds(seconds);
			} else if (difference == 0) {
				setDays(0);
				setHours(0);
				setMinutes(0);
				setSeconds(0);
			}
			if (difference < 0) {
				clearInterval(id);
			}
		}, 1000);

		return () => {
			clearInterval(id);
		};
	}, []);

	if (!countDownDateTime) {
		return null;
	}

	return (
		<Box className={classes.root}>
			{!!days && (
				<Box className={classes.dateTimeWrapper}>
					<Box className={classes.dateTime}>{formatDateTime(days)}</Box>
					<Box className={classes.dateTimeLabel}>Days</Box>
				</Box>
			)}
			<Box className={classes.dateTimeWrapper}>
				<Box className={classes.dateTime}>{formatDateTime(hours)}</Box>
				<Box className={classes.dateTimeLabel}>Hours</Box>
			</Box>
			<Box className={classes.dateTimeWrapper}>
				<Box className={classes.dateTime}>{formatDateTime(minutes)}</Box>
				<Box className={classes.dateTimeLabel}>Minutes</Box>
			</Box>
			<Box className={classes.dateTimeWrapper}>
				<Box className={classes.dateTime}>{formatDateTime(seconds)}</Box>
				<Box className={classes.dateTimeLabel}>Seconds</Box>
			</Box>
		</Box>
	);
}

Timer.propTypes = {
	dateTime: PropTypes.string.isRequired,
	isRecurring: PropTypes.bool,
	repeatEvery: PropTypes.string,
	count: PropTypes.number,
	dayList: PropTypes.array,
};

Timer.defaultProps = {};
