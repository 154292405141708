import moment from 'moment-mini';
// Constants
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;

const DAYS = {
	0: 'sunday',
	1: 'monday',
	2: 'tuesday',
	3: 'wednesday',
	4: 'thrusday',
	5: 'friday',
	6: 'saturday',
};

export const getFutureEventDate = ({
	dateTime,
	repeatEvery,
	count = 0,
	dayList,
}) => {
	let currentDateTime = new Date().getTime();
	let originalDateTime = Date.parse(dateTime);
	let difference = originalDateTime - currentDateTime;
	switch (repeatEvery) {
		case 'day': {
			if (difference > 0) {
				return Date.parse(dateTime);
			} else {
				while (originalDateTime < currentDateTime) {
					originalDateTime = moment(originalDateTime).add(count, 'days');
				}
				difference = originalDateTime - currentDateTime;
				return difference > 0
					? Date.parse(originalDateTime)
					: moment(originalDateTime).add(count, 'days');
			}
		}
		case 'week': {
			return Date.parse(dateTime);
		}
		case 'month': {
			return difference > 0
				? Date.parse(dateTime)
				: moment(dateTime).add(count ? count : 0, 'months');
		}
		default: {
			return Date.parse(dateTime);
		}
	}
};
