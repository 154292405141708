import PropTypes from 'prop-types';

// constants
import { paymentFormDataSource } from '@/utils/constants/formTypes';

// components
import FormResultModal from './partials/FormResultModal';
import FormBox from './partials/formBox';
import WithProps from './withProps';

// material
import { Close } from '@mui/icons-material';
import { Box, Dialog, IconButton, Typography } from '@mui/material';

// styles
import useStyles from './style';

function PaymentFormBoxWrap({
	open,
	formResult,
	resultModalOpen,
	handleCloseResultModal,
	successModalDetails,
	errorModalDetails,
	...props
}) {
	const { classes } = useStyles(props.styleProps);

	const [submitButtonText, setSubmitButtonText] = React.useState(
		props.submitButtonText
	);
	const [discountAmount, setDiscountAmount] = React.useState('');
	const [couponCode, setCouponCode] = React.useState('');

	const couponCodeBoxChangHandler = ({ source, value }) => {
		if (source === paymentFormDataSource.couponCode) {
			setCouponCode(value);
		} else if (source === paymentFormDataSource.discountAmount) {
			setDiscountAmount(value);
		}
	};

	const successCallback = (result, data, userData) => {
		if (!props.successCallback) return;

		const _userData = userData;
		if (discountAmount) _userData.couponCode = couponCode;
		return props.successCallback(result, data, _userData);
	};

	React.useEffect(() => {
		if (discountAmount) {
			setSubmitButtonText(
				<Typography>
					<Typography
						component="span"
						variant="h6"
						fontWeight="fontWeightSemiBold"
					>
						{`Buy @ ${discountAmount}`}
					</Typography>
					<Typography
						style={{ textDecoration: 'line-through', marginLeft: '16px' }}
						component="span"
						color="text.disabled"
						fontWeight="fontWeightMedium"
					>
						{`₹${props.paymentDetail.productAmount}/-`}
					</Typography>
				</Typography>
			);
		} else {
			setSubmitButtonText(props.submitButtonText);
		}
	}, [discountAmount]);

	const FormBoxElement = (
		<FormBox
			{...props}
			couponCodeInput={true}
			submitButtonText={submitButtonText}
			successCallback={successCallback}
			couponCodeBoxChangHandler={couponCodeBoxChangHandler}
		/>
	);

	return (
		<>
			{props.isModal ? (
				<Dialog
					fullWidth={true}
					maxWidth="sm"
					open={open}
					onClose={props.handleClose}
					classes={{ paper: classes.modalFormPaper }}
				>
					{!props.disableClose && (
						<Box className={classes.iconButtonWrap}>
							<IconButton onClick={props.handleClose}>
								<Close />
							</IconButton>
						</Box>
					)}
					<Box p={3} pt={props.disableClose ? 3 : 1}>
						{FormBoxElement}
					</Box>
				</Dialog>
			) : (
				<Box className={classes.formContainer}>{FormBoxElement}</Box>
			)}
			{props.formResult && (
				<FormResultModal
					formResult={formResult}
					resultModalOpen={resultModalOpen}
					handleClose={handleCloseResultModal}
					successModalDetails={successModalDetails}
					errorModalDetails={errorModalDetails}
				/>
			)}
		</>
	);
}

export default WithProps(PaymentFormBoxWrap);

PaymentFormBoxWrap.propTypes = {
	// basic
	open: PropTypes.bool,
	isModal: PropTypes.bool,
	// form UI
	title: PropTypes.string,
	description: PropTypes.string,
	submitButtonText: PropTypes.string,
	fullWidthSubmitButton: PropTypes.bool,
	styleProps: PropTypes.object,
	// form constants
	formName: PropTypes.string,
	constants: PropTypes.object,
	pageConstants: PropTypes.object,
	defaultFormValues: PropTypes.object,
	analyticsProps: PropTypes.object,
	disableWhatsappCheck: PropTypes.bool,
	whatsappContentText: PropTypes.string,
	// form funct
	handleClose: PropTypes.func,
	disableClose: PropTypes.bool,
	formComponent: PropTypes.any,
	mobileUiSchema: PropTypes.object,
	tabletUiSchema: PropTypes.object,
	desktopUiSchema: PropTypes.object,
	modalUiSchema: PropTypes.object,
	controlSchema: PropTypes.object,
	// form submission
	submitLink: PropTypes.string,
	submitApiAction: PropTypes.func,
	handleExtraValidations: PropTypes.func,
	formulateDataToBeSubmitted: PropTypes.func,
	successCallback: PropTypes.func,
	errorCallback: PropTypes.func,
	autoCloseResultModal: PropTypes.bool,
	disableSuccessModal: PropTypes.bool,
	successModalDetails: PropTypes.object,
	errorModalDetails: PropTypes.object,
	resultModalCallBack: PropTypes.func,
	parentSuccessRedirectLink: PropTypes.string,
	redirectToThankYouPage: PropTypes.string,
	disableRedirectAfterSuccess: PropTypes.bool,
	uploadConfig: PropTypes.object,
};

PaymentFormBoxWrap.defaultProps = {
	// basic
	isModal: false,
	open: false,
	// form UI
	styleProps: {
		noShadow: true,
		formWidth: '100%',
	},
	// form funct
	disableClose: false,
	autoCloseResultModal: true,
	disableSuccessModal: false,
	// form submission
	errorModalDetails: {
		description:
			'There was an error while trying to submit the form, please check your internet connection and try again.',
		title: 'Error',
	},
	thankYouRedirectionLink: '',
	disableRedirectAfterSuccess: false,
};
