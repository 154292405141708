const PaymentVerify = () => {
	return (
		<svg
			width="90"
			height="93"
			viewBox="0 0 90 93"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M80.4815 41.4957C83.4957 37.5954 83.7789 32.0395 80.8065 27.7856L73.1448 16.8204L65.6965 14.2065L65.8933 6.44264L65.4829 5.85524C61.8203 0.613639 54.6024 -0.666406 49.361 2.99594L15.957 26.3365L39.269 43.2106L80.4815 41.4957Z"
				fill="#5FC5FC"
			/>
			<path
				d="M68.9036 26.3228H16.4995C16.3177 26.3228 16.1371 26.3281 15.9573 26.3364L6.40454 33.0113C6.28058 33.0984 6.15863 33.1884 6.03879 33.281C1.10707 37.0184 -0.0295453 44.0167 3.54558 49.1332L4.92185 51.1029L18.8693 71.0636C22.5316 76.3051 29.7496 77.5851 34.991 73.9228L77.9476 43.9073C78.9096 43.2365 79.7639 42.4232 80.4814 41.4955V37.9005C80.4814 31.5063 75.2978 26.3228 68.9036 26.3228Z"
				fill="#33B4FB"
			/>
			<path
				d="M3.96191 49.7277L65.9208 6.43213L73.1751 16.8136L11.2164 60.1091L3.96191 49.7277Z"
				fill="#808FA4"
			/>
			<path
				d="M4.92163 51.1028L11.2071 60.0983L59.5451 26.3228H37.4413L4.92163 49.0455V51.1028Z"
				fill="#64768E"
			/>
			<path
				d="M63.9819 83.4773H11.5776C5.18356 83.4773 0 78.2939 0 71.8997V45.146C0 38.7518 5.18356 33.5684 11.5776 33.5684H63.9818C70.376 33.5684 75.5594 38.7519 75.5594 45.146V71.8997C75.5595 78.2939 70.3762 83.4773 63.9819 83.4773Z"
				fill="#8CD6FE"
			/>
			<path
				d="M24.9084 49.5423H12.9507C10.7667 49.5423 8.99634 47.7719 8.99634 45.5879V45.5799C8.99634 43.3959 10.7667 41.6255 12.9507 41.6255H24.9084C27.0924 41.6255 28.8628 43.3959 28.8628 45.5799V45.5879C28.8628 47.7719 27.0924 49.5423 24.9084 49.5423ZM63.5166 75.4062H56.1986C54.3009 75.4062 52.7625 73.8678 52.7625 71.9702V67.7808C52.7625 65.8831 54.3009 64.3447 56.1986 64.3447H63.5168C65.4144 64.3447 66.9528 65.8831 66.9528 67.7808V71.9702C66.9528 73.8678 65.4144 75.4062 63.5166 75.4062Z"
				fill="#F9F29D"
			/>
			<path
				d="M41.7353 59.8125C41.7353 57.3678 39.9643 57.1382 37.7798 57.1382C35.5953 57.1382 33.8242 57.3678 33.8242 59.8125C33.8242 62.257 35.5953 64.2387 37.7798 64.2387C39.9643 64.2387 41.7353 62.257 41.7353 59.8125Z"
				fill="#4F5C6F"
			/>
			<path
				d="M18.9293 76.657H12.8798C12.548 76.657 12.2299 76.5252 11.9953 76.2906C11.7607 76.056 11.6289 75.7379 11.6289 75.4061C11.6289 75.0744 11.7607 74.7562 11.9953 74.5216C12.2299 74.2871 12.548 74.1553 12.8798 74.1553H18.9293C19.261 74.1553 19.5792 74.2871 19.8137 74.5216C20.0483 74.7562 20.1801 75.0744 20.1801 75.4061C20.1801 75.7379 20.0483 76.056 19.8137 76.2906C19.5792 76.5252 19.261 76.657 18.9293 76.657ZM31.8873 76.657H25.8378C25.5061 76.657 25.1879 76.5252 24.9533 76.2906C24.7188 76.056 24.587 75.7379 24.587 75.4061C24.587 75.0744 24.7188 74.7562 24.9533 74.5216C25.1879 74.2871 25.5061 74.1553 25.8378 74.1553H31.8873C32.2191 74.1553 32.5372 74.2871 32.7718 74.5216C33.0064 74.7562 33.1382 75.0744 33.1382 75.4061C33.1382 75.7379 33.0064 76.056 32.7718 76.2906C32.5372 76.5252 32.2191 76.657 31.8873 76.657ZM44.8454 76.657H38.7959C38.4641 76.657 38.146 76.5252 37.9114 76.2906C37.6768 76.056 37.545 75.7379 37.545 75.4061C37.545 75.0744 37.6768 74.7562 37.9114 74.5216C38.146 74.2871 38.4641 74.1553 38.7959 74.1553H44.8454C45.1771 74.1553 45.4953 74.2871 45.7299 74.5216C45.9644 74.7562 46.0962 75.0744 46.0962 75.4061C46.0962 75.7379 45.9644 76.056 45.7299 76.2906C45.4953 76.5252 45.1771 76.657 44.8454 76.657Z"
				fill="#808FA4"
			/>
			<path
				d="M25.3033 59.4006C24.9716 59.4006 24.6534 59.2688 24.4189 59.0342C24.1843 58.7997 24.0525 58.4815 24.0525 58.1497V56.1464C24.0525 55.8146 24.1843 55.4965 24.4189 55.2619C24.6534 55.0273 24.9716 54.8955 25.3033 54.8955C25.6351 54.8955 25.9533 55.0273 26.1878 55.2619C26.4224 55.4965 26.5542 55.8146 26.5542 56.1464V58.1497C26.5542 58.4815 26.4224 58.7997 26.1878 59.0342C25.9533 59.2688 25.6351 59.4006 25.3033 59.4006ZM50.2561 59.4006C49.9244 59.4006 49.6062 59.2688 49.3717 59.0342C49.1371 58.7997 49.0053 58.4815 49.0053 58.1497V56.1464C49.0053 55.8146 49.1371 55.4965 49.3717 55.2619C49.6062 55.0273 49.9244 54.8955 50.2561 54.8955C50.5879 54.8955 50.9061 55.0273 51.1406 55.2619C51.3752 55.4965 51.507 55.8146 51.507 56.1464V58.1497C51.507 58.4815 51.3752 58.7997 51.1406 59.0342C50.9061 59.2688 50.5879 59.4006 50.2561 59.4006Z"
				fill="#4F5C6F"
			/>
			<circle cx="73.421" cy="75.513" r="16.5789" fill="#808FA4" />
			<path
				d="M77.7387 71.1956C77.9024 71.3598 78.0969 71.4901 78.3111 71.5791C78.5253 71.668 78.7549 71.7138 78.9869 71.7138C79.2188 71.7138 79.4484 71.668 79.6626 71.5791C79.8768 71.4901 80.0713 71.3598 80.235 71.1956L81.6245 69.8061C81.7981 69.6444 81.9373 69.4494 82.0338 69.2327C82.1303 69.0161 82.1822 68.7822 82.1864 68.545C82.1906 68.3079 82.147 68.0723 82.0581 67.8523C81.9693 67.6324 81.8371 67.4326 81.6694 67.2649C81.5016 67.0972 81.3018 66.9649 81.0819 66.8761C80.862 66.7873 80.6264 66.7436 80.3892 66.7478C80.1521 66.752 79.9182 66.8039 79.7015 66.9005C79.4849 66.997 79.2899 67.1362 79.1282 67.3097L77.7387 68.7149C77.4136 69.0459 77.2315 69.4913 77.2315 69.9552C77.2315 70.4192 77.4136 70.8646 77.7387 71.1956ZM80.2193 79.8308C79.8883 79.5057 79.4429 79.3236 78.979 79.3236C78.5151 79.3236 78.0697 79.5057 77.7387 79.8308C77.4089 80.1625 77.2237 80.6112 77.2237 81.079C77.2237 81.5467 77.4089 81.9955 77.7387 82.3272L79.1282 83.7166C79.2899 83.8902 79.4849 84.0294 79.7015 84.1259C79.9182 84.2224 80.1521 84.2743 80.3892 84.2785C80.6264 84.2827 80.862 84.2391 81.0819 84.1503C81.3018 84.0614 81.5016 83.9292 81.6694 83.7615C81.8371 83.5937 81.9693 83.394 82.0581 83.174C82.147 82.9541 82.1906 82.7185 82.1864 82.4814C82.1822 82.2442 82.1303 82.0103 82.0338 81.7936C81.9373 81.577 81.7981 81.382 81.6245 81.2203L80.2193 79.8308ZM69.1035 79.8308C68.7718 79.501 68.323 79.3158 67.8553 79.3158C67.3875 79.3158 66.9388 79.501 66.6071 79.8308L65.2333 81.2046C65.0598 81.3663 64.9206 81.5613 64.8241 81.7779C64.7275 81.9946 64.6756 82.2285 64.6714 82.4657C64.6672 82.7028 64.7109 82.9384 64.7997 83.1583C64.8885 83.3783 65.0208 83.578 65.1885 83.7458C65.3562 83.9135 65.556 84.0457 65.7759 84.1346C65.9959 84.2234 66.2314 84.267 66.4686 84.2628C66.7058 84.2586 66.9396 84.2067 67.1563 84.1102C67.373 84.0137 67.568 83.8745 67.7297 83.7009L69.1035 82.3115C69.4285 81.9804 69.6106 81.5351 69.6106 81.0711C69.6106 80.6072 69.4285 80.1618 69.1035 79.8308Z"
				fill="white"
			/>
			<path
				d="M73.4211 63.9341C72.9529 63.9351 72.5043 64.1215 72.1733 64.4526C71.8422 64.7836 71.6558 65.2322 71.6548 65.7004V67.6629C71.6548 68.1314 71.8409 68.5806 72.1721 68.9119C72.5034 69.2431 72.9526 69.4292 73.4211 69.4292C73.8895 69.4292 74.3388 69.2431 74.67 68.9119C75.0013 68.5806 75.1874 68.1314 75.1874 67.6629V65.7004C75.1863 65.2322 74.9999 64.7836 74.6689 64.4526C74.3379 64.1215 73.8892 63.9351 73.4211 63.9341ZM83.2338 73.7468H81.2713C80.8028 73.7468 80.3536 73.9329 80.0223 74.2642C79.6911 74.5954 79.505 75.0447 79.505 75.5131C79.505 75.9816 79.6911 76.4308 80.0223 76.7621C80.3536 77.0933 80.8028 77.2794 81.2713 77.2794H83.2338C83.7023 77.2794 84.1515 77.0933 84.4828 76.7621C84.814 76.4308 85.0001 75.9816 85.0001 75.5131C85.0001 75.0447 84.814 74.5954 84.4828 74.2642C84.1515 73.9329 83.7023 73.7468 83.2338 73.7468ZM73.4211 81.597C72.9529 81.5981 72.5043 81.7845 72.1733 82.1155C71.8422 82.4465 71.6558 82.8952 71.6548 83.3633V85.3259C71.6548 85.7943 71.8409 86.2436 72.1721 86.5748C72.5034 86.9061 72.9526 87.0922 73.4211 87.0922C73.8895 87.0922 74.3388 86.9061 74.67 86.5748C75.0013 86.2436 75.1874 85.7943 75.1874 85.3259V83.3633C75.1863 82.8952 74.9999 82.4465 74.6689 82.1155C74.3379 81.7845 73.8892 81.5981 73.4211 81.597ZM65.5709 73.7468H63.6083C63.1399 73.7468 62.6906 73.9329 62.3594 74.2642C62.0281 74.5954 61.842 75.0447 61.842 75.5131C61.842 75.9816 62.0281 76.4308 62.3594 76.7621C62.6906 77.0933 63.1399 77.2794 63.6083 77.2794H65.5709C66.0393 77.2794 66.4886 77.0933 66.8198 76.7621C67.1511 76.4308 67.3372 75.9816 67.3372 75.5131C67.3372 75.0447 67.1511 74.5954 66.8198 74.2642C66.4886 73.9329 66.0393 73.7468 65.5709 73.7468Z"
				fill="#D2D4D7"
			/>
		</svg>
	);
};

export default PaymentVerify;
