import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

//constants
import { formResultKeys } from '@/components/shared/formBoxWrap/constants';

// icons
import { CheckCircleOutline, Error } from '@mui/icons-material';
import { useTheme } from '@mui/material';

// components
const ResultModal = dynamic(() => import('@/components/shared/resultModal'), {
	ssr: false,
});

const FormResultModal = React.memo(function FormResultModal({
	formResult,
	resultModalOpen,
	handleClose,
	successModalDetails,
	errorModalDetails,
	analyticsProps,
}) {
	const theme = useTheme();

	const modalColor =
		formResult === formResultKeys.success
			? theme.palette.success.main
			: theme.palette.error.main;
	const iconStyles = {
		color: modalColor,
		width: theme.spacing(10),
		height: theme.spacing(10),
	};

	if (!resultModalOpen) {
		return null;
	}

	return (
		(successModalDetails || errorModalDetails) && (
			<ResultModal
				open={resultModalOpen}
				handleClose={handleClose}
				accentColor={modalColor}
				title={
					formResult === formResultKeys.success
						? successModalDetails.title
						: errorModalDetails.title
				}
				description={
					formResult === formResultKeys.success
						? successModalDetails.description
						: errorModalDetails.description
				}
				buttons={
					formResult === formResultKeys.success &&
					successModalDetails.successModalButtons &&
					successModalDetails.successModalButtons.length > 0
						? successModalDetails.successModalButtons
						: []
				}
				renderIcon={() => {
					if (formResult === formResultKeys.success) {
						return <CheckCircleOutline style={iconStyles} />;
					} else {
						return <Error style={iconStyles} />;
					}
				}}
				analyticsProps={analyticsProps}
				result={formResult}
			/>
		)
	);
});

FormResultModal.propTypes = {
	formResult: PropTypes.string,
	resultModalOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	successModalDetails: PropTypes.object,
	errorModalDetails: PropTypes.object,
	analyticsProps: PropTypes.object,
};

export default FormResultModal;
