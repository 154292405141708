import PropTypes from 'prop-types';

// components
import FormResultModal from './partials/FormResultModal';
import FormBox from './partials/formBox';
import WithProps from './withProps';

// material
import { Close } from '@mui/icons-material';
import { Box, Dialog, IconButton } from '@mui/material';

// styles
import useStyles from './style';

function FormBoxWrap({
	open,
	formResult,
	resultModalOpen,
	handleCloseResultModal,
	successModalDetails,
	errorModalDetails,
	...props
}) {
	const { classes } = useStyles(props.styleProps);

	return (
		<>
			{props.isModal ? (
				<Dialog
					fullWidth={true}
					maxWidth="sm"
					open={open}
					onClose={props.handleClose}
					classes={{ paper: classes.modalFormPaper }}
				>
					{!props.disableClose && (
						<Box className={classes.iconButtonWrap}>
							<IconButton onClick={props.handleClose}>
								<Close />
							</IconButton>
						</Box>
					)}
					<Box p={3} pt={props.disableClose ? 3 : 1}>
						<FormBox {...props} />
					</Box>
				</Dialog>
			) : (
				<Box className={classes.formContainer}>
					<FormBox {...props} />
				</Box>
			)}
			{formResult && (
				<FormResultModal
					formResult={formResult}
					resultModalOpen={resultModalOpen}
					handleClose={handleCloseResultModal}
					successModalDetails={successModalDetails}
					errorModalDetails={errorModalDetails}
				/>
			)}
		</>
	);
}

FormBoxWrap.propTypes = {
	// basic
	open: PropTypes.bool,
	isModal: PropTypes.bool,
	// form UI
	title: PropTypes.string,
	description: PropTypes.string,
	submitButtonText: PropTypes.string,
	fullWidthSubmitButton: PropTypes.bool,
	styleProps: PropTypes.object,
	// form constants
	formName: PropTypes.string,
	constants: PropTypes.object,
	pageConstants: PropTypes.object,
	defaultFormValues: PropTypes.object,
	analyticsProps: PropTypes.object,
	disableWhatsappCheck: PropTypes.bool,
	whatsappContentText: PropTypes.string,
	// form funct
	handleClose: PropTypes.func,
	disableClose: PropTypes.bool,
	formComponent: PropTypes.any,
	mobileUiSchema: PropTypes.object,
	tabletUiSchema: PropTypes.object,
	desktopUiSchema: PropTypes.object,
	modalUiSchema: PropTypes.object,
	controlSchema: PropTypes.object,
	// form submission
	submitLink: PropTypes.string,
	submitApiAction: PropTypes.func,
	handleExtraValidations: PropTypes.func,
	formulateDataToBeSubmitted: PropTypes.func,
	successCallback: PropTypes.func,
	errorCallback: PropTypes.func,
	autoCloseResultModal: PropTypes.bool,
	disableSuccessModal: PropTypes.bool,
	successModalDetails: PropTypes.object,
	errorModalDetails: PropTypes.object,
	resultModalCallBack: PropTypes.func,
	parentSuccessRedirectLink: PropTypes.string,
	redirectToThankYouPage: PropTypes.string,
	disableRedirectAfterSuccess: PropTypes.bool,
	uploadConfig: PropTypes.object,
};

FormBoxWrap.defaultProps = {
	// basic
	isModal: false,
	open: false,
	// form UI
	styleProps: {
		noShadow: true,
		formWidth: '100%',
	},
	// form funct
	disableClose: false,
	autoCloseResultModal: true,
	disableSuccessModal: false,
	// form submission
	errorModalDetails: {
		description:
			'There was an error while trying to submit the form, please check your internet connection and try again.',
		title: 'Error',
	},
	thankYouRedirectionLink: '',
	disableRedirectAfterSuccess: false,
};

export default WithProps(FormBoxWrap);
