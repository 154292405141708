import React from 'react';

// constants
import { formResultKeys } from './constants';
let timeoutTrack = null;

// utils
import * as eventActions from '@/utils/analytics';
import { getThankYouRedirectLink } from '@/utils/form';

export default function WithFormBoxProps(Component) {
	const WrappedComponent = (props) => {
		const [resultModalOpen, setResultModalOpen] = React.useState(false);
		const [formResult, setFormResult] = React.useState('');

		let thankYouRedirectionLink = props.parentSuccessRedirectLink;
		if (!thankYouRedirectionLink && props.redirectToThankYouPage) {
			thankYouRedirectionLink = getThankYouRedirectLink(
				props.redirectToThankYouPage
			);
		}

		const analyticsProps = {
			...props.analyticsProps,
			formTitle: props.formName,
			isFormModal: !!props.isModal,
		};

		// tracking call wrapper
		let trackEvent = (eventName, eventProperties) => {
			eventActions.trackEvent(eventName, {
				...analyticsProps,
				...eventProperties,
			});
		};

		// handler for result modal closing
		const handleCloseResultModal = () => {
			// clear already executing timeout function
			timeoutTrack !== null && clearTimeout(timeoutTrack);

			// track result modal closed
			trackEvent('RESULT_MODAL_CLOSED', {
				eventLabel: 'CROSS_ICON',
				eventAction: 'CLOSED',
				eventCategory: 'USER_EVENT',
				subSection: 'RESULT_MODAl',
				formResult,
			});

			// hide result modal
			setResultModalOpen(false);

			// callback on result modal close
			props.resultModalCallBack && props.resultModalCallBack(formResult);
		};

		// handle submit api success/failure
		const handleResultCallback = (result) => {
			// close modal for modal type form
			if (props.isModal && result == formResultKeys.success) {
				props.handleClose();
			}

			let disableResultModal = false;
			if (result === formResultKeys.success) {
				disableResultModal =
					Boolean(!props.successModalDetails) ||
					Boolean(props.disableSuccessModal) ||
					Boolean(thankYouRedirectionLink);
			}

			setFormResult(result);
			if (!disableResultModal) {
				// display result modal
				setResultModalOpen(true);
				if (props.autoCloseResultModal) {
					timeoutTrack = setTimeout(() => {
						setResultModalOpen(false);
						props.resultModalCallBack && props.resultModalCallBack(result);
					}, 5000);
				}
			}
		};

		const onChangeHandler = (formData, errors) => {
			props.onChangeHandler && props.onChangeHandler(formData, errors);
		};

		// on close handler for modal/drawer
		const handleClose = () => {
			if (props.disableClose) return;

			trackEvent('FORM_MODAL_CLOSED', {
				eventLabel: 'CROSS_ICON',
				eventAction: 'CLICKED',
				eventCategory: 'USER_EVENT',
				subSection: 'FORM_MODAl',
			});
			props.handleClose();
		};

		return (
			<Component
				timer={props.timer}
				// form UI
				title={props.title}
				description={props.description}
				submitButtonText={props.submitButtonText}
				fullWidthSubmitButton={props.fullWidthSubmitButton}
				styleProps={props.styleProps}
				// form constants
				formName={props.formName}
				constants={props.constants}
				pageConstants={props.pageConstants}
				defaultFormValues={props.defaultFormValues}
				analyticsProps={props.analyticsProps}
				disableWhatsappCheck={props.disableWhatsappCheck}
				whatsappContentText={props.whatsappContentText}
				// form funct
				disableClose={props.disableClose}
				handleClose={handleClose}
				formComponent={props.formComponent}
				onChangeHandler={onChangeHandler}
				mobileUiSchema={props.mobileUiSchema}
				tabletUiSchema={props.tabletUiSchema}
				desktopUiSchema={props.desktopUiSchema}
				modalUiSchema={props.modalUiSchema}
				controlSchema={props.controlSchema}
				trackEvent={trackEvent}
				// form submission
				submitLink={props.submitLink}
				submitApiAction={props.submitApiAction}
				handleExtraValidations={props.handleExtraValidations}
				formulateDataToBeSubmitted={props.formulateDataToBeSubmitted}
				successCallback={props.successCallback}
				errorCallback={props.errorCallback}
				handleResultCallback={handleResultCallback}
				thankYouRedirectionLink={thankYouRedirectionLink}
				disableRedirectAfterSuccess={props.disableRedirectAfterSuccess}
				uploadConfig={props.uploadConfig}
				paymentDetail={props.paymentDetail}
				// modal
				isModal={props.isModal}
				open={props.open}
				onClose={props.handleClose}
				successModalDetails={props.successModalDetails}
				errorModalDetails={props.errorModalDetails}
				formResult={formResult}
				resultModalOpen={resultModalOpen}
				handleCloseResultModal={handleCloseResultModal}
			/>
		);
	};

	return WrappedComponent;
}
