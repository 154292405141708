import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	dialogPaper: {
		background: 'none',
		boxShadow: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		overflowY: 'unset',
		position: 'relative',
		margin: '16px',
		width: '100%',
	},
	infoWrap: {
		position: 'absolute',
		borderRadius: '20px',
		backgroundColor: theme.palette.common.white,
		width: '466px',
		minHeight: '272px',
		padding: '34px 72px',
		opacity: 1,
		textAlign: 'center',
		transition: 'opacity 0.3s ease-in-out!important',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '16px',
			margin: 0,
			justifyContent: 'center',
			width: '100%',
		},
	},
	spinnerWrap: {
		position: 'absolute',
		opacity: 1,
	},
	title: {
		position: 'relative',
		width: '100%',
		margin: '16px 0',
		[theme.breakpoints.down('sm')]: {
			fontSize: '16px',
			margin: '0 0 16px',
		},
	},
	zeroOpacity: {
		opacity: 0,
	},
}));

export default useStyles;
